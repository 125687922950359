<template>
  <div>
    <b-card id="machine-chart">
      <h1 class="bold-text larger-font">Taisin LMS Machine Dashboard</h1>
      <b-row class="machine-container">
        <b-col v-for="(machine, index) in machines" :key="index" cols="12" md="6" lg="3">
          <div class="mb-2">
            <table class="border-machine">
              <tr>
                <td colspan="2" class="border-child pa-1 text-center">
                  <span class="bold-text large-font">{{ machine._id }}</span><br>
                </td>
              </tr>
              <tr>
                <td colspan="2" class="border-child pa-1 text-center">
                  <span class="bold-text large-font">User <br> {{ defaultUser(machine.latestData.user) }}</span>
                </td>
              </tr>
              <tr>
                <td class="border-child pa-1" style="width: 50%; border-right: 1px solid #252525;">
                  <span class="bold-text large-font">Status <br> {{ machine.latestData.status }}</span>
                </td>
                <td class="border-child pa-1">
                  <span class="bold-text large-font">Speed <br> {{ formatSpeed(machine.latestData.machineSpeed * 60) }} <br> m/minutes</span>
                </td>
              </tr>
            </table>
          </div>
          <b-row v-if="index % 4 === 3"></b-row>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
export default {
  data() {
    return{
      startLoop: true
    }
  },
  methods: {
    formatSpeed(speed){
      if (!speed) {
        return 0
      }
      var value = speed.toFixed(1) ;
      return parseFloat(value)
    },
    defaultUser(user){
      if (user===undefined || user==="") {
        return "no user"
      }
      return user
    },
    getDataDashboard(){
      console.log(this.startLoop);
      this.$store.dispatch("machineDashboard/getDashboardToday").then((res) => {
        setTimeout(() => {
        if (this.startLoop) {
          this.getDataDashboard();
         }
        }, 2000);
      }).catch((err) => {
        setTimeout(() => {
        if (this.startLoop) {
          this.getDataDashboard();
         }
        }, 2000);
      })
    }
  },
  computed: {
    machines() {
      return this.$store.getters["machineDashboard/getDashboardMachineToday"]
    },
  },
  mounted() {
    this.getDataDashboard()
  },
  destroyed() {
    this.startLoop = false
  }
}
</script>

<style scoped>

.machine-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 30px;
}

.border-machine {
  border: 1px solid #252525;
  border-block-end: 0;
  border-collapse: collapse;
  inline-size: 100%;
  text-align: center;
}

.border-child {
  height: 100%;
  border-block-end: 1px solid #252525;
}

.bold-text {
  font-weight: bold;
}

.large-font {
  font-size: 28px;
}

.larger-font {
  font-size: 28px;
}

.machine-card {
  width: 250px;
  height: 200px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  margin: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #f8f9fa;
  color: #2c3e50;
}
</style>